import React from 'react';
import { Layout } from 'src/components/Layout';
import { SignUpPage } from 'src/components/SignUpPage/component';
import { Client, ClientManager } from 'src/helpers/ClientManager';
import { GroupManager } from 'src/helpers/GroupManager';
import { RegistrationManager } from 'src/helpers/RegistrationManager';
import { UserManager } from 'src/helpers/UserManager';
import { AssessmentOnlyValue } from 'src/utils/generateProductsAndServices';

interface Props {
	path: string;
	location: {
		href: string;
		origin: string;
		protocol: string;
		host: string;
		hostname: string;
		port: string;
		pathname: string;
		search: string;
		hash: string;
		state: {
			key: string;
		};
		key: string;
	};
	pageContext: {
		client: Client;
	};
}

interface State {
	loading: boolean;
	success: boolean;
	client: Client | undefined;
}

export default class Registration extends React.Component<Props, State> {
	state: State = { loading: true, success: false, client: undefined };

	componentDidMount = async () => {
		const client = ((await ClientManager.getClients()) || []).find(
			(client) => client.id == this.props.pageContext?.client?.id
		);
		this.setState({ loading: false, client });
	};

	render() {
		const client = this.state.client;
		return (
			<Layout suppressVerificationReminder={true}>
				<SignUpPage
					controller={this}
					headingText="Welcome to Xceptional"
					bodyText={`You have been invited by ${
						!!client ? client.name : `an external provider`
					} to complete our assessments. Get started by creating an account.`}
					groups={client?.group?.id ? [Number(client?.group?.id)] : undefined}
					assessmentOnly={client?.assessmentOnly}
					source={client?.name}
					removeDefaultGroups={!!client?.group?.id}
					onUserCreated={(user) => {
						if (user && user.id) {
							RegistrationManager.createRegistration({
								user: user.id,
								client: client?.id ? { connect: { id: client.id } } : undefined,
								group: client?.group?.id ? { connect: { id: client.group.id } } : undefined,
								returnUrl: client?.returnUrl,
								couponCode:
									client?.couponCode?.active &&
									(client.couponCode.limitedUses ? client.couponCode.remainingUses > 0 : true)
										? { connect: { id: Number(client.couponCode.id) } }
										: undefined,
								created: new Date(Date.now()),
								createdBy: user.id,
								//accepted: Date;
							});
						} else {
							console.error(`Unable to create registration as created user invalid`);
						}
					}}
				/>
			</Layout>
		);
	}
}
