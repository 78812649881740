import { ApolloManager } from 'src/helpers/ApolloManager';
import { Registration } from '../RegistrationManager';
import getClients from './getClients.graphql';
import { CouponCode } from '../CouponCodeManager/CouponCode';

export interface Client {
	id?: number;
	name?: string;
	active?: boolean;
	assessmentOnly?: boolean;
	registrations?: Registration[];
	returnUrl?: string;
	group?: {
		id?: number;
	};
	couponCode?: CouponCode;
	created?: Date;
	createdBy?: number;
}

export interface AllClientsQuery {
	keystone: {
		allClients: Client[];
	};
}

class ClientImplementation {
	public readonly getClients = async (): Promise<Client[] | undefined> => {
		try {
			const result = await ApolloManager.client.query<{ clients: Client[] }>({
				query: getClients,
				variables: {},
				fetchPolicy: 'no-cache',
			});
			if (result.data && result.data.clients && result.data.clients.length > 0) {
				return result.data.clients;
			}
		} catch (error) {
			console.error(`Client data retrieval failed. ${error}`);
		}
		return undefined;
	};
}

export const ClientManager = new ClientImplementation();
